import React from "react";
import { Modal } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import "./footer.scss";
import { Link } from "react-router-dom";

interface Props extends WithTranslation {
  actionButtons: JSX.Element[] | JSX.Element;
}

class Footer extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      //state properties here
    };
  }
  render() {
    return (
      <footer id="footer" className="footer py-3">
        <div className="container">
          <div className="d-flex flex-column">
            <span className="text-muted">
              Copyright © {new Date().getFullYear()} Louis Bélet SA - {this.props.t("footer_developped_by")} <a href="https://www.novadev.ch/" target="_blank">Novadev Sàrl</a>  - Version 1.3.0
            </span>
            <span className="text-muted">
              {/* <a href="https://www.novadev.ch/" target="_blank">Politique de confidentialité</a> */}
              <Link to="/privacy-policy">
                {this.props.t("menu.menu_privacy_policy")}
              </Link>
            </span>
          </div>

          {/* <button className="btn btn-primary text-white export-button">Exporter la sélection <span className="icon icon-export"></span></button> */}
          <div className="actionButtonsWrapper">{this.props.actionButtons}</div>
        </div>
      </footer>
    );
  }
}

export default withTranslation("common")(Footer);
